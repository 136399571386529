/* stylelint-disable */
/* Targeting number inputs within MUI DataGridPro */
.MuiDataGrid-root input[type='number']::-webkit-inner-spin-button,
.MuiDataGrid-root input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.MuiDataGrid-root input[type='number'] {
    -moz-appearance: textfield;
}
/* stylelint-enable */
