/* stylelint-disable */

/* Button Specific Styling */
.fc .fc-today-button {
    background-color: #12446d;
}

.fc .fc-next-button {
    background-color: #12446d;
}

.fc .fc-prev-button {
    background-color: #12446d;
}

.fc .fc-toolbar-title {
    color: #12446d;
}

/* eslint-disable */
.fc .fc-dayGridMonth-button {
    background-color: #12446d;
}

/* eslint-disable */
.fc .fc-timeGridWeek-button {
    background-color: #12446d;
}

/* eslint-disable */
.fc .fc-timeGridDay-button {
    background-color: #12446d;
}

/* Event Styling */
.fc .fc-event {
    color: white;
    border: none;
}

.fc .fc-col-header-cell-cushion {
    color: #e87a00
}

/* Day Grid */
.fc .fc-daygrid-day {
    border-color: #0d75a6;
}

.fc .fc-col-header-cell {
    border: .1rem solid #0d75a6;
}

.fc .fc-scrollgrid {
    border: .1rem solid #0d75a6;
}
/* stylelint-enable */
