@keyframes ring-bell-pop {
    0% {
        transform: rotate(0deg) scale(1);
    }

    5% {
        transform: rotate(2deg) scale(1.3);
    }

    10% {
        transform: rotate(15deg) scale(1.3);
    }

    20% {
        transform: rotate(-15deg) scale(1.3);
    }

    30% {
        transform: rotate(12deg) scale(1.2);
    }

    40% {
        transform: rotate(-12deg) scale(1.2);
    }

    50% {
        transform: rotate(8deg) scale(1.1);
    }

    60% {
        transform: rotate(-8deg) scale(1.1);
    }

    70% {
        transform: rotate(4deg) scale(1.05);
    }

    80% {
        transform: rotate(-4deg) scale(1.05);
    }

    90% {
        transform: rotate(2deg) scale(1);
    }

    100% {
        transform: rotate(0deg) scale(1);
    }
}

.ring-pop {
    transform-origin: 50% 10%;
    animation: ring-bell-pop 0.8s ease-out;
}

@keyframes slide-in-out {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    15% {
        transform: translateX(0);
        opacity: 1;
    }

    85% {
        transform: translateX(0);
        opacity: 1;
    }

    100% {
        transform: translateX(100%);
        opacity: 0;
    }
}

.notification-slide {
    position: absolute;
    top: 50px;
    right: 10px;
    background-color: #fff;
    color: #000;
    padding: 8px 12px;
    border-radius: 4px;
    box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
    animation: slide-in-out 2s ease forwards;
}


